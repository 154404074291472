import React from "react"
import Layout from "../layouts/layout"

const IndexPage = ({ pageContext }) => {
  return (
    <Layout
      footerless="true"
      lang={pageContext.lang}
      tpl={pageContext.templateGT}
      pageContext={pageContext}
      introModifier="intro--index"
      intro="not-animated"
    />
  )
}

export default IndexPage
  